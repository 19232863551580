import * as React from 'react';
import Typography from '@mui/material/Typography';
import Treatment from './Treatment';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';
import { pushToHubSpot } from '../../helpers/hubspot';

function CarePlan(props) {
  const { email, plan = '', createdAt = '', treatments = [], visitId = '', index = 0 } = props;
  const date = new Date(createdAt);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const [isExpanded, setIsExpanded] = useState(index === 0);

  const handleChange = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!isExpanded) return;

    pushToHubSpot([
      ['identify', { email: email }],
      ['trackEvent', 'care_plan_viewed'],
    ]);
  }, [isExpanded, email]);

  return (
    <React.Fragment>
      <Accordion expanded={isExpanded} onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{formattedDate}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant={'body1'} paragraph style={{ whiteSpace: 'pre-line' }}>
            {plan}
          </Typography>
          {treatments &&
            treatments.map(treatment => {
              let key = `treatment-${visitId}-${treatment.id}-${index}`;
              return (
                <React.Fragment key={key}>
                  <Treatment treatment={treatment} visitId={visitId} />
                </React.Fragment>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}

export default CarePlan;
